import cn from 'classnames';
import styles from './Experience.module.scss';
import { Title } from '@/components/Title';
import { CompanyCard } from '@/components/CompanyCard';
import { Tag, TagVariant } from '@/components/Tag';
import { useTranslations } from 'next-intl';

type ExperienceProps = {
  className?: string;
};

export function Experience({ className }: ExperienceProps) {
  const wrapperClassName = cn(styles.wrapper, className);
  const t = useTranslations();

  return (
    <div className={wrapperClassName} id="experience">
      <Title text={t('experience.title')} className={styles.title} />
      <div className={styles.companies}>
        <CompanyCard
          title="Cloud.ru"
          link="https://cloud.ru/"
          tag={<Tag text={t('experience.current')} />}
          className={styles.cloud}
          animationDuration={0.9}
          desc={
            <ul className={styles.list}>
              <li className={styles.listItem}>
                <span className={styles.symbol}>✦</span>&nbsp;&nbsp;
                {t('experience.architecture')}
              </li>
              <li className={styles.listItem}>
                <span className={styles.symbol}>✦</span>&nbsp;&nbsp;
                {t('experience.ui')}
              </li>
              <li className={styles.listItem}>
                <span className={styles.symbol}>✦</span>
                &nbsp;&nbsp;{t('experience.deploy')}
              </li>
              <li className={styles.listItem}>
                <span className={styles.symbol}>✦</span>
                &nbsp;&nbsp;{t('experience.analytics')}
              </li>
              <li className={styles.listItem}>
                <span className={styles.symbol}>✦</span>&nbsp;&nbsp;
                {t('experience.conf')}
              </li>
              <li className={styles.listItem}>
                <span className={styles.symbol}>✦</span>&nbsp;&nbsp;
                {t('experience.review')}
              </li>
              <li className={styles.listItem}>
                <span className={styles.symbol}>✦</span>&nbsp;&nbsp;
                {t('experience.interview')}
              </li>
              <li className={styles.listItem}>
                <span className={styles.symbol}>✦</span>&nbsp;&nbsp;
                {t('experience.npm')}
              </li>
            </ul>
          }
        />
        <div className={styles.col}>
          <div className={styles.row}>
            <CompanyCard
              title="Air Production"
              link="https://air.agency/"
              className={styles.air}
              animationDuration={0.6}
              desc={
                <ul className={styles.list}>
                  <li className={styles.listItem}>
                    <span className={styles.symbol}>✦</span>
                    &nbsp;&nbsp;{t('experience.seo')}
                  </li>
                  <li className={styles.listItem}>
                    <span className={styles.symbol}>✦</span>
                    &nbsp;&nbsp;{t('experience.optimization')}
                  </li>
                  <li className={styles.listItem}>
                    <span className={styles.symbol}>✦</span>
                    &nbsp;&nbsp;{t('experience.develop')}
                  </li>
                </ul>
              }
            />
            <CompanyCard
              title="AltComSystems"
              tag={
                <Tag
                  text={t('experience.startup')}
                  variant={TagVariant.Secondary}
                />
              }
              link=""
              unhovered
              className={styles.startup}
              animationDuration={0.9}
              desc={
                <ul className={styles.list}>
                  <li className={styles.listItem}>
                    <span className={styles.symbol}>✦</span>
                    &nbsp;&nbsp;{t('experience.frontend')}
                  </li>
                  <li className={styles.listItem}>
                    <span className={styles.symbol}>✦</span>
                    &nbsp;&nbsp;{t('experience.codeReview')}
                  </li>
                  <li className={styles.listItem}>
                    <span className={styles.symbol}>✦</span>
                    &nbsp;&nbsp;{t('experience.publishing')}
                  </li>
                </ul>
              }
            />
          </div>
          <div className={styles.row}>
            <CompanyCard
              title="Now Agency"
              link="https://now-agency.ru/"
              className={styles.now}
              animationDuration={0.9}
              desc={
                <ul className={styles.list}>
                  <li className={styles.listItem}>
                    <span className={styles.symbol}>✦</span>
                    &nbsp;&nbsp;{t('experience.fullstack')}
                  </li>
                  <li className={styles.listItem}>
                    <span className={styles.symbol}>✦</span>
                    &nbsp;&nbsp;{t('experience.clouds')}
                  </li>
                  <li className={styles.listItem}>
                    <span className={styles.symbol}>✦</span>
                    &nbsp;&nbsp;{t('experience.sql')}
                  </li>
                </ul>
              }
            />
            <CompanyCard
              title="Lost Lore LLC"
              link="https://www.lost-lore.com/"
              className={styles.freelance}
              animationDuration={0.9}
              desc={
                <ul className={styles.list}>
                  <li className={styles.listItem}>
                    <span className={styles.symbol}>✦</span>
                    &nbsp;&nbsp;{t('experience.techs')}
                  </li>
                  <li className={styles.listItem}>
                    <span className={styles.symbol}>✦</span>
                    &nbsp;&nbsp;{t('experience.web3')}
                  </li>
                  <li className={styles.listItem}>
                    <span className={styles.symbol}>✦</span>
                    &nbsp;&nbsp;{t('experience.miniApp')}
                  </li>
                  <li className={styles.listItem}>
                    <span className={styles.symbol}>✦</span>
                    &nbsp;&nbsp;{t('experience.native')}
                  </li>
                  <li className={styles.listItem}>
                    <span className={styles.symbol}>✦</span>
                    &nbsp;&nbsp;{t('experience.bots')}
                  </li>
                </ul>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
